import { IonButton, IonIcon, IonLoading, IonRow, IonText, IonToast } from '@ionic/react';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SignatureCanvas from 'react-signature-canvas';
import { v4 as uuidv4 } from 'uuid';
import clearSignature from '../../../img/clear-signature.svg';
import { SignatureActions } from '../../../redux/actions/signatureActions';
import { walletActions } from '../../../redux/actions/walletActions';
import { filesService } from '../../../services';
import { CardInfoEntity } from '../../../shared/entity/cardInfo.module';
import { ProviderSignatureEntity } from '../../../shared/entity/providerSignatureEntity';
import { UserEntity } from '../../../shared/entity/userEntity';
import './DigitalSignature.css';
const DigitalSignature: React.FC<CardInfoEntity> = ({ title, zoom, showShareCard, editMethod, editStatus }) => {
	const dispatch = useDispatch();
	const [viewMore, setViewMore] = useState(false);
	const [showToastAttachments, setShowToastAttachments] = useState(false);
	const [showLoading, setShowLoading] = useState(false);
	const data = useSelector<any, any>((state) => state.wallet.data!);
	const providerSignature = useSelector<any, ProviderSignatureEntity>((state) => state.providerSignature!);
	const [toastMessageError, setToastMessageError] = useState('The file has already been added.');

	const [flip, setFlip] = useState(false);
	const [back, setBack] = useState('card card__payer background_payer');
	const user = useSelector<any, UserEntity>((state) => state.user!);
	const sigCanvas: any = useRef({});
	const [imageURL, setImageURL]: any = useState();
	const [edit, setEdit] = useState(false);
	useEffect(() => {
		if (data) {
			dispatch(SignatureActions.setSignature(data));
		}
	}, [data]);
	useEffect(() => {
		if (providerSignature && providerSignature?.providerSignature?.length > 0) {
			setImageURL(providerSignature.providerSignature[0].downloadUrl);
			setEdit(true);
		} else if (editMethod) {
			editMethod(true);
		}
	}, [providerSignature]);
	useEffect(() => {
		if (editStatus) {
			setEdit(!edit);
			setImageURL(undefined);
		}
	}, [editStatus]);

	const clear = () => sigCanvas.current.clear();
	async function save() {
		try {
			setShowLoading(true);
			setImageURL(sigCanvas?.current?.getTrimmedCanvas()?.toDataURL('image/png'));
			const data = await uploadSignaturePhoto();
			let result: any;
			if (providerSignature?.id) {
				result = await filesService.updateDocuments(user.id!, data, providerSignature?.id);
			} else {
				result = await filesService.saveDocumentSignature(user.id!, data);
			}
			if (result?.data) {
				const tmpUserToken: string = JSON.parse(localStorage.getItem('NuCoUserToken')!);
				await dispatch(walletActions.authorization(tmpUserToken));
				setShowLoading(false);
				setToastMessageError('Data updated correctly.');
				if (editMethod) {
					editMethod(false);
				}
			} else {
				setShowLoading(false);
				setToastMessageError('Something went wrong,please try again.');
				setShowLoading(true);
			}
		} catch (error) {
			setShowLoading(false);
			setToastMessageError('Something went wrong,please try again.');
		}
	}
	const uploadSignaturePhoto = async () => {
		const tenantId = data?.tenants[0]?.tenant.id;
		const uuid = uuidv4();
		const fileName = `${uuid}-signature.png`;
		const base64 = sigCanvas?.current?.getTrimmedCanvas()?.toDataURL('image/png');
		const blob = dataURItoBlob(base64);
		const res = await filesService.credentials(tenantId!, fileName, 'documentAttachments');
		const formData = new FormData();
		formData.append('key', res.data.uploadCredentials?.fields?.key);
		formData.append('policy', res.data.uploadCredentials?.fields?.policy);
		formData.append('x-goog-algorithm', res.data.uploadCredentials?.fields['x-goog-algorithm']);
		formData.append('x-goog-credential', res.data.uploadCredentials?.fields['x-goog-credential']);
		formData.append('x-goog-date', res.data.uploadCredentials?.fields['x-goog-date']);
		formData.append('x-goog-signature', res.data.uploadCredentials?.fields['x-goog-signature']);
		formData.append('file', blob, fileName);
		await filesService.upload(res.data.uploadCredentials?.url, formData);
		const pic: any = {
			createdAt: new Date(),
			downloadUrl: res.data.downloadUrl,
			id: uuid,
			name: 'signature.png',
			privateUrl: res.data.privateUrl,
			publicUrl: null,
			new: true,
			size: blob.size,
		};
		const result: any = {
			datetime: providerSignature.datetime ? providerSignature.datetime : moment(),
			provider: data?.tenants?.[0]?.provider?.id,
			providerEmail: data?.email,
			providerSignature: [pic],
			type: '64a5f8b6486b17c977e7daa9',
			username: data?.tenants?.[0]?.provider?.fullName,
			lastUpdated: moment().format('YYYY-MM-DD'),
		};
		return result;
	};
	function dataURItoBlob(dataURI: any) {
		const byteString = atob(dataURI.split(',')[1]);
		const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
		const ab = new ArrayBuffer(byteString.length);
		const ia = new Uint8Array(ab);

		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}

		return new Blob([ab], { type: mimeString });
	}
	function cancelSignature() {
		if (providerSignature && providerSignature?.providerSignature?.length > 0) {
			setImageURL(providerSignature.providerSignature[0].downloadUrl);
			setEdit(!edit);
		}
		if (editMethod) {
			if (providerSignature && providerSignature?.providerSignature?.length > 0) {
				editMethod(false);
			} else {
				editMethod(true);
			}
		}
	}
	function imageContent() {
		return <img src={imageURL} alt="my signature" className="signature-image" />;
	}
	function signatureContent() {
		return (
			<div className="signature-container">
				<SignatureCanvas
					ref={sigCanvas}
					canvasProps={{
						className: 'signature-canvas',
						onTouchStart: (e) => e.preventDefault(),
						onTouchMove: (e) => e.preventDefault(),
					}}
				/>
				<div className="clear-button">
					<IonIcon id="about" icon={clearSignature} onClick={() => clear()}></IonIcon>
				</div>
			</div>
		);
	}
	function handleEdit() {
		setEdit(!edit);
		setImageURL(undefined);
		if (editMethod) {
			editMethod(true);
		}
	}
	function signatureButtonsContent() {
		return (
			<IonRow className="row-btn-signature">
				{!imageURL ? (
					<IonRow className="auto-center ion-padding" onClick={() => cancelSignature()}>
						<IonText className="cancel-signature">CANCEL</IonText>
					</IonRow>
				) : null}
				<IonRow className="auto-center ion-padding btn-use-signature">
					{imageURL ? (
						<IonButton
							onClick={() => handleEdit()}
							color={'secondary'}
							shape="round"
							type="button"
							className="sign-in-width">
							EDIT
						</IonButton>
					) : null}
					{!imageURL ? (
						<IonButton
							onClick={() => {
								setEdit(!edit);
								save();
							}}
							color={'secondary'}
							shape="round"
							type="button"
							className="sign-in-width">
							USE
						</IonButton>
					) : null}
				</IonRow>
			</IonRow>
		);
	}

	return (
		<div className={back}>
			<div className={`${!zoom || flip ? 'wrapper' : 'wrapper-zoom-payer'}`}>
				{!flip ? (
					<IonRow className="article-header_payer">
						<IonRow className="category-title-signature">
							<IonText className="ion-padding-right">{title}</IonText>
						</IonRow>
					</IonRow>
				) : null}
				{!flip && (
					<div>
						<IonRow>
							<IonText className={`description-title-signature `}>
								{viewMore ? (
									<span>
										The Medical Provider{' '}
										<span style={{ fontWeight: 'bold' }}>{data?.tenants?.[0]?.provider?.fullName}</span> authorizes
										their designated NuCo Ambassador to act as their agent for all provider credentialing matters. This
										includes the authority to sign applications, forms, and communications necessary for obtaining, and
										renewing credentials. By signing, the Medical Provider acknowledges their consent to grant the
										Authorized Representative the power to sign on their behalf.
									</span>
								) : (
									<span>
										The Medical Provider{' '}
										<span style={{ fontWeight: 'bold' }}>{data?.tenants?.[0]?.provider?.fullName}</span> authorizes
										their designated NuCo Ambassador to act as their agent for all provider credentialing matters.
									</span>
								)}
							</IonText>
						</IonRow>
						<IonRow onClick={() => setViewMore(!viewMore)}>
							<IonText className={`view-more-desc-signature `}>{viewMore ? 'View Less' : 'View More'}</IonText>
						</IonRow>
						<div className="ion-margin">
							{imageURL ? imageContent() : signatureContent()}
							{signatureButtonsContent()}
						</div>
					</div>
				)}
				<IonLoading
					cssClass="spinner"
					isOpen={showLoading}
					onDidDismiss={() => setShowLoading(false)}
					spinner="crescent"
					translucent={true}
				/>
				<IonToast
					isOpen={showToastAttachments}
					onDidDismiss={() => setShowToastAttachments(false)}
					message={toastMessageError}
					duration={3000}
				/>
			</div>
		</div>
	);
};

export default DigitalSignature;
